html {
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 기본 스크롤바 숨기기 */
::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

/* 스크롤바 트랙 숨기기 */
::-webkit-scrollbar-track {
  background: transparent;
}

/* 스크롤바 썸 스타일 */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

/* 스크롤 중일 때만 스크롤바 표시 */
::-webkit-scrollbar-thumb:vertical,
::-webkit-scrollbar-thumb:horizontal {
  width: 10px;
  height: 10px;
}

/* Firefox용 스크롤바 스타일 */
* {
  scrollbar-width: none;
}

/* IE와 Edge용 스크롤바 스타일 */
body {
  -ms-overflow-style: none;
}

/* 스크롤 중일 때 스크롤바 표시 (모든 브라우저) */
*:hover::-webkit-scrollbar,
*:active::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*:hover::-webkit-scrollbar-thumb,
*:active::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
}

/* 스크롤 가능한 요소에 대해 오버플로우 설정 */
.scrollable {
  overflow: auto;
}