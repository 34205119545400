#LG .chatEtcMenu {
    grid-template-rows: 100px 38px 25px;
    grid-template-columns: 100vw;
    height: 170px !important;
    align-items: center;
}

#LG .chatEtcMenu > * {
    grid-column: 1 / 3;
    margin: 0 auto;
}

#LG .chatEtcMenuSelectBar {
    width: 520px !important;
}

#LG .nickname {
    width: 60px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
}

#LG .chatEtcMenuContent {
    margin-left: 17px;
    gap: 13.6px;
}

#LG .chatEtcMenuButton {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lgCategory {
    border-radius: 10px;
    width: 48px;
    height: 18px;
    line-height: 18px;
    margin: 8px auto 0;
    font-size: 10px;
}