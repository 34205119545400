.monitorList {
    margin-top: 10px;
    width: 100vw;
}


.monitorContent {
    height: 30px;
    display: grid;
    font-size: 13px;
    grid-template-columns: 12px auto 301px 73px;
    column-gap: 10px;
    align-items: end;
}

.monitorBar {
    height: auto;
    color: #7A7D7E;
    font-size: 12px;
    line-height: 14.5px;
    align-items: center;
}